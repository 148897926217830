$mat-assignment: (
  50: #fef1e1,
  100: #fddbb5,
  200: #fcc383,
  300: #fbab51,
  400: #fa992c,
  500: #f98707,
  600: #f87f06,
  700: #f77405,
  800: #f66a04,
  900: #f55702,
  A100: #ffffff,
  A200: #ffefe8,
  A400: #ffcbb5,
  A700: #ffb99c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-stock-status: (
  50: #f4e1fe,
  100: #e3b5fd,
  200: #d083fc,
  300: #bd51fb,
  400: #ae2cfa,
  500: #a007f9,
  600: #9806f8,
  700: #8e05f7,
  800: #8404f6,
  900: #7302f5,
  A100: #ffffff,
  A200: #f1e8ff,
  A400: #d3b5ff,
  A700: #c49cff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-counting: (
  50: #e3fae0,
  100: #b9f2b3,
  200: #8bea80,
  300: #5ce14d,
  400: #39da26,
  500: #16d400,
  600: #13cf00,
  700: #10c900,
  800: #0cc300,
  900: #06b900,
  A100: #e2ffe2,
  A200: #b0ffaf,
  A400: #7dff7c,
  A700: #64ff63,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-receipt: (
  50: #ffe4e7,
  100: #ffbbc4,
  200: #ff8e9d,
  300: #ff6075,
  400: #ff3e58,
  500: #ff1c3a,
  600: #ff1934,
  700: #ff142c,
  800: #ff1125,
  900: #ff0918,
  A100: #ffffff,
  A200: #fff5f5,
  A400: #ffc2c4,
  A700: #ffa8ac,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-return: (
  50: #e1f2ef,
  100: #b5ddd7,
  200: #83c7bc,
  300: #51b1a1,
  400: #2ca08c,
  500: #078f78,
  600: #068770,
  700: #057c65,
  800: #04725b,
  900: #026048,
  A100: #90ffde,
  A200: #5dffcf,
  A400: #2affbf,
  A700: #11ffb8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-picking: (
  50: #eaedff,
  100: #cad3ff,
  200: #a7b5ff,
  300: #8397ff,
  400: #6981ff,
  500: #4e6bff,
  600: #4763ff,
  700: #3d58ff,
  800: #354eff,
  900: #253cff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #d3d7ff,
  A700: #b9c0ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

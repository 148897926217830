/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --mdc-typography-button-letter-spacing: 0;
  --mdc-dialog-supporting-text-tracking: 0;
  --mdc-typography-body1-letter-spacing: 0;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

a {
  text-decoration: none;
  color: black;
}

.mat-bottom-sheet-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

// Custo pour la fenêtre de zoom d'image
.zoom-dialog .mat-dialog-container {
  position: relative;
  padding: 0;
  overflow: visible;
}

.mat-icon-button {
  @apply flex items-center justify-center #{!important};
}

@use '@angular/material' as mat;

@import 'theme-colors.scss';

@include mat.core();

/**
* Style par défaut
*/
// define theme color
$default_izy-theme-primary: mat.define-palette(mat.$indigo-palette);
$default_izy-theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// create theme
$default_izy-theme: mat.define-light-theme(
  (
    color: (
      primary: $default_izy-theme-primary,
      accent: $default_izy-theme-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($default_izy-theme);

/**
* Module id : ASSIGNMENT
*/
// define theme color
$assignment_izy-theme-primary: mat.define-palette($mat-assignment, 700);
$assignment_izy-theme-accent: mat.define-palette($mat-assignment, A400);

// create theme
$assignment_izy-theme: mat.define-light-theme(
  (
    color: (
      primary: $assignment_izy-theme-primary,
      accent: $assignment_izy-theme-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// specify theme class eg: <body class="my-theme"> ... </body>
.ASSIGNMENT-izy-theme {
  @include mat.all-component-colors($assignment_izy-theme);

  .mat-mdc-mini-fab.mat-primary {
    --mdc-fab-icon-color: white;
    --mat-mdc-fab-color: white;
  }

  // classe exposant la couleur primary en bg-color
  .bg-primary {
    background-color: mat.get-color-from-palette($assignment_izy-theme-primary);
    color: mat.get-color-from-palette($assignment_izy-theme-primary, default-contrast);
  }

  // Mat-Button-toogle checked
  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($assignment_izy-theme-primary) !important;
    .mat-button-toggle-label-content {
      color: white;
    }
  }

  button.mat-primary {
    color: white !important;
  }
}

/**
* Module id : PICKING
*/
// define theme color
$picking_izy-theme-primary: mat.define-palette($mat-picking, 500);
$picking_izy-theme-accent: mat.define-palette($mat-picking, A400);

// create theme
$picking_izy-theme: mat.define-light-theme(
  (
    color: (
      primary: $picking_izy-theme-primary,
      accent: $picking_izy-theme-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// specify theme class eg: <body class="my-theme"> ... </body>
.PICKING-izy-theme {
  @include mat.all-component-colors($picking_izy-theme);
  // classe exposant la couleur primary en bg-color
  .bg-primary {
    background-color: mat.get-color-from-palette($picking_izy-theme-primary);
    color: mat.get-color-from-palette($picking_izy-theme-primary, default-contrast);
  }

  // Mat-Button-toogle checked
  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($picking_izy-theme-primary) !important;
    .mat-button-toggle-label-content {
      color: white;
    }
  }
}

/**
* Module id : RECEIPT
*/
// define theme color
$receipt_izy-theme-primary: mat.define-palette($mat-receipt, 600);
$receipt_izy-theme-accent: mat.define-palette($mat-receipt, A400);

// create theme
$receipt_izy-theme: mat.define-light-theme(
  (
    color: (
      primary: $receipt_izy-theme-primary,
      accent: $receipt_izy-theme-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// specify theme class eg: <body class="my-theme"> ... </body>
.RECEIPT-izy-theme {
  @include mat.all-component-colors($receipt_izy-theme);
  // classe exposant la couleur primary en bg-color
  .bg-primary {
    background-color: mat.get-color-from-palette($receipt_izy-theme-primary);
    color: mat.get-color-from-palette($receipt_izy-theme-primary, default-contrast);
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($receipt_izy-theme-primary) !important;
    .mat-button-toggle-label-content {
      color: white;
    }
  }
}

/**
* Module id : RETURN
*/
// define theme color
$return_izy-theme-primary: mat.define-palette($mat-return, 600);
$return_izy-theme-accent: mat.define-palette($mat-return, A400);

// create theme
$return_izy-theme: mat.define-light-theme(
  (
    color: (
      primary: $return_izy-theme-primary,
      accent: $return_izy-theme-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// specify theme class eg: <body class="my-theme"> ... </body>
.RETURN-izy-theme {
  @include mat.all-component-colors($return_izy-theme);
  // classe exposant la couleur primary en bg-color
  .bg-primary {
    background-color: mat.get-color-from-palette($return_izy-theme-primary);
    color: mat.get-color-from-palette($return_izy-theme-primary, default-contrast);
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($return_izy-theme-primary) !important;
    .mat-button-toggle-label-content {
      color: white;
    }
  }
}

/**
* Module id : COUNTING
*/
// define theme color
$counting_izy-theme-primary: mat.define-palette($mat-counting, 600);
$counting_izy-theme-accent: mat.define-palette($mat-counting, A400);

// create theme
$counting_izy-theme: mat.define-light-theme(
  (
    color: (
      primary: $counting_izy-theme-primary,
      accent: $counting_izy-theme-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// specify theme class eg: <body class="my-theme"> ... </body>
.COUNTING-izy-theme {
  @include mat.all-component-colors($counting_izy-theme);
  // classe exposant la couleur primary en bg-color
  .bg-primary {
    background-color: mat.get-color-from-palette($counting_izy-theme-primary);
    color: mat.get-color-from-palette($counting_izy-theme-primary, default-contrast);
  }
  // Mat-Button-toogle checked
  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($counting_izy-theme-primary) !important;
    .mat-button-toggle-label-content {
      color: white;
    }
  }

  button.mat-primary {
    color: white !important;
  }
}

/**
* Module id : STOCK_STATUS
*/
// define theme color
$stock-status_izy-theme-primary: mat.define-palette($mat-stock-status, 900);
$stock-status_izy-theme-accent: mat.define-palette($mat-stock-status, A400);

// create theme
$stock-status_izy-theme: mat.define-light-theme(
  (
    color: (
      primary: $stock-status_izy-theme-primary,
      accent: $stock-status_izy-theme-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// specify theme class eg: <body class="my-theme"> ... </body>
.STOCK_STATUS-izy-theme {
  @include mat.all-component-colors($stock-status_izy-theme);
  // classe exposant la couleur primary en bg-color
  .bg-primary {
    background-color: mat.get-color-from-palette($stock-status_izy-theme-primary);
    color: mat.get-color-from-palette($stock-status_izy-theme-primary, default-contrast);
  }
}

// classe exposant la couleur primary en bg-color
.bg-primary {
  background-color: mat.get-color-from-palette($default_izy-theme-primary);
  color: mat.get-color-from-palette($default_izy-theme-primary, default-contrast);
}
// Pour utiliser la couleur primary d'un module à son extérieur
.bg-ASSIGNMENT-primary {
  background-color: mat.get-color-from-palette($assignment_izy-theme-primary);
  color: mat.get-color-from-palette($assignment_izy-theme-primary, default-contrast);
}
.bg-PICKING-primary {
  background-color: mat.get-color-from-palette($picking_izy-theme-primary);
  color: mat.get-color-from-palette($picking_izy-theme-primary, default-contrast);
}
.bg-RECEIPT-primary {
  background-color: mat.get-color-from-palette($receipt_izy-theme-primary);
  color: mat.get-color-from-palette($receipt_izy-theme-primary, default-contrast);
}
.bg-RETURN-primary {
  background-color: mat.get-color-from-palette($return_izy-theme-primary);
  color: mat.get-color-from-palette($return_izy-theme-primary, default-contrast);
}
.bg-COUNTING-primary {
  background-color: mat.get-color-from-palette($counting_izy-theme-primary);
  color: mat.get-color-from-palette($counting_izy-theme-primary, default-contrast);
}
.bg-STOCK_STATUS-primary {
  background-color: mat.get-color-from-palette($stock-status_izy-theme-primary);
  color: mat.get-color-from-palette($stock-status_izy-theme-primary, default-contrast);
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  font-size: 12px !important;
  padding: 0 !important;
}

.mat-mdc-cell:first-of-type,
.mat-mdc-header-cell:first-of-type {
  padding-left: 12px !important;
}

.mat-mdc-cell:last-of-type,
.mat-mdc-header-cell:last-of-type {
  padding-right: 12px !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: #ccc !important;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-tracking: 0;
}

.mat-column-colGroup1 {
  font-size: 14px !important;
}

.mdc-list-item__primary-text {
  width: 100%;
}
